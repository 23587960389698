<template>
    <svg
      id="Component_10_1"
      data-name="Component 10 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      @click="watchListHander"
    >
      <g
        id="Ellipse_5"
        data-name="Ellipse 5"
        transform="translate(27 27) rotate(180)"
        :fill="showAddtoWatchlist ? BackCodeDeactive : BackCodeActive"
        stroke="#79797b" 
        stroke-width="1"
      >
        <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
        <circle cx="13.5" cy="13.5" r="13" fill="none" />
      </g>
      <path
        id="Path_19"
        data-name="Path 19"
        d="M17.04,12.493H9.083v2.273H17.04Zm0-3.41H9.083v2.273H17.04ZM9.083,18.177h3.41V15.9H9.083ZM22.724,15.9v2.273H20.451v2.273H18.177V18.177H15.9V15.9h2.273V13.63h2.273V15.9Z"
        transform="translate(-2.083 -1.083)"
        :fill= "showAddtoWatchlist ? colorCodeActive : colorCodeDeactive"
      />
    </svg>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import firebase from "firebase/app";
import "firebase/database";
import FirebaseActions from "@/mixins/FirebaseActions";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { _projectName } from "@/provider-config.js";

export default {
  props: {
    content: {
      type: Object,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    screen: {
      type: Object,
    },
  },
  data() {
    return {
      showAddtoWatchlist: true,
      action: null,
      fillColor: null,
      colorCodeActive: '#79797b',
      colorCodeDeactive: '#FFFFFF',
      BackCodeActive: '#EC1C65',
      BackCodeDeactive: 'none'

    };
  },
  watch: {
    content() {
      this.fetchButtonState();
    },
    subscriberid() {
      if (this.subscriberid) {
        this.fetchButtonState();
      }
    },
  },
  computed: {
    ...mapGetters(["subscriberid", "profileid"]),
  },
  created() {
    this.fetchButtonState();
    this.fillColor =
      _projectName === "VLIVE"
        ? "#EC1C65"
        : _projectName === "NET5"
        ? "#FF9600"
        : _projectName === "NAMMAFLIX"
        ? "#ffffff"
        : _projectName === "Noor Play"
        ? "#EC1C65"
        : "";
  },
  methods: {
   
    watchListHander () {
      if (this.subscriberid) {
        if (this.showAddtoWatchlist) {
          this.addToWatchList()
        } else {
          this.removeFromWatchlist()
        } 
      } else {
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    fetchButtonState() {
      if (!this.subscriberid) {
        this.showAddtoWatchlist = true;
        return;
      }
      if (!this.content) return;

      let content = this.content;

      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

      if ("seriesid" in content && content.seriesid) {
        // let seriesid = content.seriesid;
        path += `${content.objectid}/episodes/${content.objectid}`;
      } else {
        path += `${content.objectid}`;
      }
      firebase
        .database()
        .ref(path)
        .on("value", (snapshot) => {
          let response = snapshot.val();
          //   console.log("snapshot", snapshot.val());
          if (response && response.inwatchlist == true) {
            this.showAddtoWatchlist = false;
            this.action = "REMOVE";
          } else if (response && response.inwatchlist == false) {
            this.showAddtoWatchlist = true;
            this.action = "ADD";
          } else {
            this.showAddtoWatchlist = true;
            this.action = "ADD";
          }
          //   console.log("watchlist action", this.action);
        });
    },
    addToWatchList() {
      if (this.subscriberid) {
        this.showAddtoWatchlist = false;
        // this.content.orientation = this.screen.displayType;
        this.updateContentWatchlist(this.content, true);
        // if (this.action == "ADD") {
        //   console.log("update with add");
        //   this.updateContentWatchlist(this.content, true);
        // } else if (this.action == "REMOVE") {
        //   console.log("update with remove");
        //   this.updateContentWatchlist(this.content, false);
        // }

        //logging watchlist google analytics event.
        let GA = {
          action_name: "Add to watchlist",
        };
        this.userActionEvent(GA);
      } else {
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    removeFromWatchlist() {
      if (this.subscriberid) {
        this.showAddtoWatchlist = true;
        this.updateContentWatchlist(this.content, false);

        //logging watchlist google analytics event.
        let GA = {
          action_name: "Remove from watchlist",
        };
        this.userActionEvent(GA);
      }
    },
  },
  mixins: [FirebaseActions, googleAnalytics],
};
</script>

<style>
  @media only screen and (min-width: 800px) {
    #Component_10_1:hover g { 
        fill: #848486;
    }
    #Component_10_1:hover path { 
        fill: #ffffff;
    }
  }
</style>
